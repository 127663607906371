<template>
  <b-row>
    <b-col cols="12">
      <b-card class="card-custom card-stretch gutter-b" no-body>
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <div class="card-title text-left">
            <div class="card-label font-weight-bolder">
              {{ $t(`actionPlan.tabs.${module}.chart`) }}
            </div>
          </div>
          <div class="card-toolbar"></div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body d-flex flex-column p-2 m-0">
          <!--begin::Chart-->
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          />
        </div>
      </b-card>
    </b-col>
    <b-col
      xl="6"
      lg="6"
      md="12"
      sm="12"
      v-for="action in orderedActions"
      :key="action.dimensionId"
    >
      <b-card no-body class="card-custom p-9 card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 pt-1">
          <div class="card-title text-left d-flex flex-column">
            <div
              class="card-label font-weight-bolder"
              style="font-size: 1.4em; font-weight: 300;"
            >
              <span
                v-html="action.executeAction.title"
                style="font-weight: 300!important; line-height: 1.1em; padding-right: 1em;"
              />
            </div>
          </div>
          <div
            class="card-toolbar"
            style="position: absolute; right: 3em; top: 3em;"
          >
            <b-badge variant="success" style="font-size: 1em;">
              {{ $n(action.count / totalUsers, "percent") }}
            </b-badge>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div
          class="card-body d-flex flex-column text-left"
          style="font-size: 1.2em; line-height: 2em;"
        >
          <p v-html="action.executeAction.description" />
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";

import { getColorByValueAndBase } from "@/utils/helpers";

export default {
  name: "Awareness",
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    actions: {
      type: Array,
      required: false
    },
    totalUsers: {
      type: Number,
      required: false
    },
    module: {
      type: String,
      required: true
    }
  },
  computed: {
    orderedActions() {
      return _.orderBy(this.actions, [el => el.count], ["desc"]);
    },
    actualDate() {
      return moment(new Date()).format("L");
    },
    series() {
      return [
        {
          name: this.$t(`actionPlan.tabs.general.measure`),
          data: _.sortBy(_.values(this.data), [])
        }
      ];
    },
    colors() {
      var result = [];
      for (var i = 0; i < this.actions.length; i++) {
        result.push(
          getColorByValueAndBase(
            i,
            this.actions.length > 10 ? 10 : this.actions.length,
            true
          )
        );
      }
      return this.actions.length > 6 ? _.reverse(result) : result;
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "bar"
        },
        colors: this.colors,
        plotOptions: {
          bar: {
            borderRadius: 6,
            columnWidth: "45%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          position: "left"
        },
        xaxis: {
          categories: _.keys(this.data),
          labels: {
            show: false,
            style: {
              fontSize: "12px"
            }
          }
        }
      };
    }
  }
};
</script>
