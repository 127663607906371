<template>
  <b-row>
    <b-col cols="12">
      <b-card class="card-custom p-9 card-stretch gutter-b" no-body>
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <div class="card-title text-left">
            <div class="card-label font-weight-bolder">
              {{ $t("actionPlan.tabs.general.chart") }}
            </div>
          </div>
          <div class="card-toolbar"></div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body d-flex flex-column">
          <!--begin::Chart-->
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          />
        </div>
      </b-card>
    </b-col>
    <b-col
      xl="6"
      lg="6"
      md="12"
      sm="12"
      v-for="(action, index) in actions"
      :key="action.dimensionId"
    >
      <b-card no-body class="card-custom p-9 card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 pt-1">
          <div class="card-title text-left d-flex flex-column">
            <div
              class="card-label font-weight-bolder"
              style="font-size: 1.4em; font-weight: 300;"
            >
              <span
                v-html="action.executeAction.title"
                style="font-weight: 300!important; line-height: 1.1em; padding-right: 1em;"
              />
              <div
                class="font-size-sm text-dark-50 mt-3"
                style="font-size: 1.2rem; font-weight: 300;"
              >
                {{
                  $t("actionPlan.tabs.general.recommendations", {
                    index: index + 1,
                    date: actualDate
                  })
                }}
              </div>
            </div>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div
          class="card-body d-flex flex-column text-left"
          style="font-size: 1.2em; line-height: 2em;"
        >
          <p v-html="action.executeAction.description" />
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";

import { getColorByValueAndBase } from "@/utils/helpers";

export default {
  name: "GeneralPlan",
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    actions: {
      required: false
    }
  },
  computed: {
    actualDate() {
      return moment(new Date()).format("L");
    },
    series() {
      return [
        {
          name: this.$t(`actionPlan.tabs.general.measure`),
          data: _.values(this.data)
        }
      ];
    },
    colors() {
      var result = [];
      for (var i = 0; i < 6; i++) {
        result.push(getColorByValueAndBase(i + 1, 6, true));
      }
      return result;
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "bar"
        },
        colors: this.colors,
        plotOptions: {
          bar: {
            borderRadius: 6,
            columnWidth: "45%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: _.keys(this.data),
          labels: {
            style: {
              fontSize: "12px"
            }
          }
        }
      };
    }
  }
};
</script>
