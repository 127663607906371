<template>
  <b-row id="reports">
    <b-col cols="12">
      <div
        class="subheader py-2 py-lg-4 subheader-transparent"
        id="kt_subheader"
      >
        <div
          class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
        >
          <!--begin::Toolbar-->
          <div class="d-flex align-items-center">
            <!--begin::Button-->
            <b-button
              variant="light-primary"
              class="font-weight-bold"
              @click="createActionPlan"
            >
              <span class="svg-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:class="'ml-3'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5.857 2h7.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0120 7.529v12.554c0 1.79-.02 1.917-1.857 1.917H5.857C4.02 22 4 21.874 4 20.083V3.917C4 2.127 4.02 2 5.857 2z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 14H9a1 1 0 010-2h2v-2a1 1 0 012 0v2h2a1 1 0 010 2h-2v2a1 1 0 01-2 0v-2z" fill="#000"/></g></svg>
                Crear
              </span>
            </b-button>
            <!--end::Button-->
          </div>
          <!--end::Toolbar-->
        </div>
      </div>
    </b-col>
    <b-col xl="2" lg="3" md="6" v-for="file in files" :key="file.assetId">
      <b-card no-body class="card-custom gutter-b card-stretch">
        <div class="card-body">
          <div class="d-flex flex-column align-items-center">
            <!--begin: Icon-->
            <img
              alt=""
              class="max-h-65px"
              svg-inline
              :src="require(`@/assets/images/files/${file.type}.svg`)"
            />
            <!--end: Icon-->
            <!--begin: Tite-->
            <b-button
              tag="a"
              @click="apiCall(file.url, file.link)"
              class="text-dark-75 font-weight-bold mt-10 font-size-lg"
              variant="outline-default"
            >
              {{ file.link }}
            </b-button>
            <!--end: Tite-->
          </div>
        </div>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      class="d-flex flex-column justify-content-center"
      v-if="files.length < 1"
    >
      <b-card class="card-custom gutter-b card-stretch">
        <div class="">
          <simple-svg
            :src="require('@/assets/images/icons/no-documents.svg')"
          />
        </div>
        <span class="font-size-h6 font-weight-bold text-dark-75">
          {{ $t("actionPlan.empty.description") }}
        </span>
        <div class="mt-7">
          <b-button
            variant="light"
            @click="createActionPlan"
            class="btn-small font-weight-bold"
            pill
          >
            {{ $t("actionPlan.empty.button") }}
          </b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { generateActionPlan } from "@/api/controller/stakeholders.api";
import { SimpleSVG } from "vue-simple-svg";

export default {
  name: "Reports",
  components: {
    "simple-svg": SimpleSVG
  },
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    files() {
      return this.data;
    }
  },
  methods: {
    createActionPlan() {
      const stakeholderCompanyId = localStorage.getItem("managedCompanyId");
      generateActionPlan(stakeholderCompanyId).then(() => {
        this.$swal(
          "En proceso",
          "El plan de acción se esta generando en segundo plano, le avisaremos vía email cuando haya finalizado el proceso",
          "success"
        );
      });
    },
    apiCall(uri, name) {
      this.axios.get(uri).then(response => {
        let a = document.createElement("a");
        a.href =
          "data:" +
          response.data.records.type +
          ";base64," +
          response.data.records.content;
        a.download = name + ".zip";
        a.click();
        this.loading = false;
      });
    }
  }
};
</script>
