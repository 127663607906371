p<template>
  <div id="action-plan">
    <div class="subheader subheader-transparent" id="kt_subheader">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Details-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Title-->
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            {{ $t("menu.controller.actionPlan.title") }}
          </h5>
          <!--end::Title-->
        </div>
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <box-tabs :tabs="tabs" @changeTab="select" />
        <general-plan
          v-if="selected === 'general'"
          :data="usersByRisk"
          :actions="actionsByRisk"
        />
        <awareness
          v-if="selected === 'awareness'"
          :data="usersByAwareness"
          :actions="actionsByAwareness"
          :totalUsers="usersnum"
          :module="selected"
        />
        <awareness
          v-if="selected === 'follow'"
          :data="usersByFollow"
          :actions="actionsByFollow"
          :totalUsers="usersnum"
          :module="selected"
        />
        <awareness
          v-if="selected === 'wellbeing'"
          :data="usersByWellbeing"
          :actions="actionsByWellbeing"
          :totalUsers="usersnum"
          :module="selected"
        />
        <reports v-if="selected === 'reports'" :data="reports" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import BoxTabs from "@/components/menu/BoxTabs.component";

import GeneralPlan from "@/components/action/GeneralPlan.component";
import Awareness from "@/components/action/Awareness.component";
import Reports from "@/components/action/Reports.component";

export default {
  name: "ActionPlan",
  components: {
    GeneralPlan,
    Awareness,
    BoxTabs,
    Reports
  },
  data() {
    return {
      selected: "general"
    };
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Auth", ["hasModule"]),
    ...mapGetters("ActionPlan", [
      "isRiskLoaded",
      "isRestLoaded",
      "usersByRisk",
      "actionsByRisk",
      "usersByAwareness",
      "actionsByAwareness",
      "usersByWellbeing",
      "actionsByWellbeing",
      "usersByFollow",
      "actionsByFollow",
      "reports"
    ]),
    ...mapGetters("Company", ["usersnum"]),
    tabs() {
      return [
        {
          id: "general",
          title: this.$t("actionPlan.tabs.general.title"),
          image: "building.svg"
        },
        {
          id: "awareness",
          title: this.$t("actionPlan.tabs.awareness.title"),
          image: "shield-protected.svg"
        },
        {
          id: "follow",
          title: this.$t("actionPlan.tabs.follow.title"),
          image: "diagnostics.svg"
        },
        {
          id: "wellbeing",
          title: this.$t("actionPlan.tabs.wellbeing.title"),
          image: "home-heart.svg"
        },
        {
          id: "reports",
          title: this.$t("actionPlan.tabs.reports.title"),
          image: "chart-line2.svg"
        }
      ];
    }
  },
  methods: {
    ...mapActions("Company", ["loadDashboard"]),
    ...mapActions("ActionPlan", ["loadGeneralPlan", "loadRestPlan"]),
    select(tab) {
      this.selected = tab;
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isDashboardLoaded) {
      this.loadDashboard();
    }
    if (
      localStorage.getItem("managedCompanyId") &&
      this.isConstantsActive &&
      !this.isRiskLoaded
    ) {
      this.loadGeneralPlan();
    }
  },
  watch: {
    isConstantsActive(value) {
      if (localStorage.getItem("managedCompanyId") && value === true) {
        this.loadDashboard();
        this.loadGeneralPlan();
      }
    },
    isDashboardLoaded(value) {
      if (this.isConstantsActive && value === false) {
        this.loadDashboard();
      }
    },
    isRiskLoaded(value) {
      if (
        localStorage.getItem("managedCompanyId") &&
        this.isConstantsActive &&
        value === false
      ) {
        this.loadGeneralPlan();
      }
    },
    selected(value) {
      if (value !== "general" && !this.isRestLoaded) {
        this.loadRestPlan();
      }
    }
  }
};
</script>
